<template>
  <HeaderFullpage :title="t('showcase.prices.createNew')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase
    v-else
    gap="40"
  >
    <PricesForm ref="pricesForm" :predefined-data="predefinedData" :handler="createPrice" />

    <SuccessModal
      ref="successModal"
      @retry="pricesForm.resetState()"
      @close="router.back()"
    />
  </LayoutBase>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { HeaderFullpage } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { PageLoader } from '@/components/PageLoader'
import SuccessModal from '../../workspace/SuccessModal.vue'
import { errorToast } from '@/helpers/showToast'
import showcasesApi from '@/screens/Account/api/showcases.api'

import PricesForm from '../../workspace/PricesForm.vue'

defineProps({
  predefinedData: {
    type: Object,
    default: () => {},
  },
})

const router = useRouter()
const { t } = useI18n()
const isLoading = ref(false)
const successModal = ref()

const route = useRoute()

const createPrice = async (payload) => {
  const response = await showcasesApi.create(route.params.id, payload)

  const isSuccess = response.data.success
  if (!isSuccess) {
    errorToast({ description: t('toast.somethingWrongLong') })
  }
  if (isSuccess) {
    successModal.value.openModal(t('showcase.prices.createSuccess'))
  }
}

const pricesForm = ref(null);

</script>

<style lang="scss" scoped>
[theme="dark"] {
  .price-description {
    color: white;
  }
}
</style>

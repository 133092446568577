<template>
  <PanePromise
    id="fsdf3223f"
    ref="PanePromiseREF"
    fit-content
    disabled
  >
    <LayoutBase gap="20">
      <p class="modal__title">
        {{ title }}
      </p>
      <ButtonBase
        v-if="!hideNewEvent"
        class="modal__btn"
        @click="newEvent"
      >
        {{ t('common.addMore') }}
      </ButtonBase>
      <ButtonBase
        class="modal__btn"
        variant="text"
        @click="hideModal"
      >
        {{ t('common.close') }}
      </ButtonBase>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref } from 'vue'
import { LayoutBase } from '@/components/Layouts'
import { ButtonBase, PanePromise } from '@/UI'

defineProps({
  hideNewEvent: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['retry', 'close'])
const PanePromiseREF = ref()
const title = ref('')

const open = async (titleText) => {
  title.value = titleText
  await PanePromiseREF.value.open()
}

const newEvent = async () => {
  PanePromiseREF.value.close()
  await new Promise((res) => setTimeout(res, 1000))
  emit('retry')
}

const hideModal = async () => {
  PanePromiseREF.value.close()
  emit('close')
}

defineExpose({
  openModal: open,
})
</script>

<style scoped lang="scss">
.card {
  border-radius: 40px 40px 0px 0px;
  background: #f9fafd;
  border: 1px solid #edeffd;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.05);
}

.modal__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #6b7090;
}
.modal__btn {
  width: 100%;
  height: 60px;
}
</style>

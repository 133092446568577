import { Device } from '@capacitor/device';
import { successToast } from '@/helpers/showToast'

import { Share } from '@capacitor/share';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
export const share = async ({ title = '', description = '', url = '' }, t) => {
  const linkTitle = t('share.linkTitle', { title, description, url })

  const { platform } = await Device.getInfo();
  if (platform === 'web') {
    if (isMobile) {
      try {
        await navigator.share({ title: linkTitle })
        successToast({ description: t('share.linkCopied') })
      } catch (error) {
        console.log(error)
        // errorToast({ description: 'Не получилось скопировать ссылку' })
      }
    }
    else {
      try {
        await navigator.clipboard.writeText(url)
        successToast({ description: t('share.linkCopied') })
      } catch (error) {
        console.log(error)
        // errorToast({ description: 'Не получилось скопировать ссылку' })
      }
    }
    return
  }

  try {
    await Share.share({ text: linkTitle })
  } catch (error) {
    console.log(error)
    // errorToast({ description: 'Не получилось скопировать ссылку' })
  }

}


import { api } from '@/app/api'

const processLocales = ({ list, field, t, te }) => list.map(item => {
  const name1 = te(`${field}.${item.id}.name_1`) ? t(`${field}.${item.id}.name_1`) : null;
  const name2 = te(`${field}.${item.id}.name_2`) ? t(`${field}.${item.id}.name_2`) : null;

  const resultObj = item;

  const isObj = te(`${field}.${item.id}.name`)
  if (isObj) {
    resultObj.name = t(`${field}.${item.id}.name`)
  } else {
    resultObj.name = t(`${field}.${item.id}`)
  }
  if (name1) {
    resultObj.name_1 = name1;
  }
  if (name2) {
    resultObj.name_2 = name2;
  }

  return resultObj;
})

export const fetchCategory = async ({ t, te }) => {
  try {
    const { data } = (await api.get('/v1/categories'))
    data.categories = processLocales({ list: data.categories, field: 'categoriesList', t, te })

    return data
  } catch (error) {

    console.error(error)
  }
}
export const fetchAnimalTypes = async ({ t, te }) => {
  try {
    const { data } = (await api.get('/v1/animals/types'))
    data.animals = processLocales({ list: data.animals, field: 'animalTypesList', t, te })

    return data
  } catch (error) {

    console.error(error)
  }
}
export const fetchServices = async ({ t, te }) => {
  try {
    const { data } = (await api.get('/v1/accounts/types'))
    data.types = processLocales({ list: data.types, field: 'accountTypesList', t, te })

    return data
  } catch (error) {

    console.error(error)
  }
}
export const fetchProps = async ({ t, te }) => {
  try {
    const { data } = (await api.get('/v1/props'))
    data.props = processLocales({ list: data.props, field: 'propsList', t, te })
    return data
  } catch (error) {

    console.error(error)
  }
}

export const fetchVolumes = async () => {
  try {
    const { data } = await api.get('/v1/showcases/volumes')
    return data.options
  } catch (error) {
    console.error(error)
  }
}

export const fetchCurrencies = async () => {
  try {
    const { data } = await api.get('/v1/currencies')
    return data.currencies
  } catch (error) {

    console.error(error)
  }
}

export const uploadPhoto = async ({ photos }) => {

  const formData = new FormData()

  const donePhotos = []
  photos.forEach(photo => {
    if (photo?.id) {
      donePhotos.push(photo)
    } else {formData.append('file', photo)}
  })

  try {
    const { data } = (await api.post('/v1/files/upload',
      formData
    ))
    data.paths = [...data.paths, ...donePhotos]
    return data
  } catch (error) {

    console.error(error)
  }
}

export const getUser = async () => {
  try {
    const { data } = (await api.get('/v3/profile'))
    return { user: data.profile }
  } catch (error) {
    console.error(error)
  }
}

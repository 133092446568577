<template>
  <Teleport
    v-if="isLoaded"
    to="#followElement"
  >
    <div
      v-if="!hideDrag"
      class="drag"
    />
    <slot />
  </Teleport>
</template>

<script setup>
import { onMounted, ref } from 'vue';
const isLoaded = ref(false)

onMounted(() => {
  isLoaded.value = true
})

defineProps({
  hideDrag: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="scss" scoped>
.drag {
    background: rgba(88, 88, 88, 0.6);
    height: 8px;
    width: 60px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;

    transform: translate(-50%, -20px);
    opacity: 0;

    animation: slidein 3s ease-in-out infinite;
    animation-iteration-count: infinite;

}

@keyframes slidein {
    0% {
        transform: translateX(-50%);
        opacity: 1;
    }

    50% {
        transform: translate(-50%, -8px);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%);
        opacity: 1;

    }
}
</style>

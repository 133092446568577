<template>
  <div
    class="wrapp-selector"
    :class="{ onlyView: props.onlyView }"
  >
    <template v-for="item in filterBySubtitle">
      <template v-if="item.payload.length === 0 && !(props.onlyView && !types?.includes(item.id))">
        <div class="wrap">
          <InputCheckbox
            :title="item.name"
            :model-value="types.includes(item.id)"
            @click="handleClickType({ isActive: types.includes(item.id), type: item.id })"
          />
          <TextareaBase
            v-if="item.field && types.includes(item.id)"
            :value="model[item.id]"
            :placeholder="t('common.description')"
            @input="test($event, item)"
          />
        </div>
      </template>
      <div
        v-else-if="!(props.onlyView && !types?.includes(item.id))"
        class="box"
      >
        <div class="title">
          {{ item.name }}
        </div>
        <div class="props-wrap">
          <div
            v-for="props in item.payload"
            class="props"
            @click="handleClickProp({ isActive: catprops.includes(item.id + ':' + props.id), catprop: item.id + ':' + props.id })"
          >
            <span>
              {{ props.name }}
            </span>
            <CheckboxBase :is-active="catprops.includes(item.id + ':' + props.id)" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useMainEntities } from '@/states/mainEntities'
import { InputCheckbox, CheckboxBase, TextareaBase } from '@/UI'

const mainEntities = useMainEntities()
const props = defineProps({
  category: {
    type: Number,
    required: true
  },
  catprops: {
    type: Array,
    required: true
  },
  types: {
    type: Array,
    default: () => [],
  },
  fields: {
    type: Object,
  },
  execType: {
    type: Number,
    default: 1
  },
  onlyView: {
    type: Boolean,
    default: false
  }
})

const model = computed({
  get() {
    return props.fields
  },
  set(val) {
    emit('update:fields', val)
  }
})

const test = (e, item) => {
  model.value[item.id] = e.target.value
}

const emit = defineEmits(['update:catprops', 'update:types', 'update:fields'])

const handleClickProp = ({ isActive, catprop }) => {
  if (isActive) {
    emit('update:catprops', props.catprops.filter(el => el !== catprop))
  } else {
    emit('update:catprops', [...props.catprops, catprop])
  }
}

const handleClickType = ({ isActive, type }) => {
  if (isActive) {
    emit('update:types', props.types.filter(el => el !== type))
  } else {
    emit('update:types', [...props.types, type])
  }
}

watch(() => props.category, () => {
  emit('update:catprops', [])
  emit('update:types', [])
})

const currentServices = computed(() => mainEntities.services.filter(el => el.category_id.includes(props.category)))
const servicesWithProps = computed(() => currentServices.value.map(el => ({
  ...el,
  payload: (el.payload || []).map(id => (mainEntities.props.find(el => el.id === id)))
})))

const filterBySubtitle = computed(() => servicesWithProps.value.filter(el => {
  if (el.subtype == 3) {return true}
  return el.subtype !== props.execType
}))
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .box {
        background: #37363C;
        border: 1px solid #37363C;
    }

    .props {
        color: #fff;
    }
}

.wrap {
    display: grid;
    gap: 10px;
}

.box {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 20px;
    display: grid;
    gap: 20px;
}

.title {
    color: #5B5F7C;
    font-size: 15px;
    font-weight: 600;
}

.props {
    display: grid;
    grid-template-columns: 1fr auto;
}

.props-wrap {
    display: grid;
    gap: 10px;
}

.wrapp-selector {
    display: grid;
    gap: 10px;

    &.onlyView {
        pointer-events: none;
    }
}
</style>

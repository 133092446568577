<template>
  <div class="enter-phone-step">
    <div class="enter-phone-step__top">
      <h4 class="auth__subtitle">
        {{ t('auth.ph.enterNumber') }}
      </h4>

      <SelectCoutryCodes v-model="countryShortName" />

      <InputBasePhone
        v-model:model-value="authStore.phoneNumber"
        v-model:country-code="countryShortName"
        :label="t('auth.ph.number')"
        @enter="sendPhone"
      />

      <div style="margin-bottom: 20px;" />

      <ButtonBase
        :loading="loading"
        :disabled="Boolean(authStore.timeout)"
        class="enter-phone-step__bottom__button"
        @click="sendPhone()"
      >
        {{ authStore.timeout ? `${t('auth.wait')} ${authStore.timeout} ${t('auth.sec')}` : t('common.next') }}
      </ButtonBase>
      <ButtonBase variant="transparent" style="margin-bottom: 10px;" @click="setDemoMode">
        {{ t('auth.ph.demoMode') }}
      </ButtonBase>
    </div>

    <div class="enter-phone-step__bottom">
      <slot name="footer" />

      <router-link
        to="/terms"
        class="enter-phone-step__bottom__terms"
      >
        {{ t('auth.politics') }}
      </router-link>
      <div
        class="enter-phone-step__bottom__version subtitle smooth"
        :class="{
          'is-ready': version.length
        }"
        style="height: 20px"
      >
        {{ version }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ButtonBase, InputBasePhone, SelectCoutryCodes } from '@/UI'
import { errorToast, warningToast } from '@/helpers/showToast'
import { onMounted, ref, watch } from 'vue'
import { getDevice } from '@/helpers/device'
import { useI18n } from 'vue-i18n'
import { isValidPhoneNumber } from 'libphonenumber-js';

import { useRouter } from 'vue-router'
import { useAuthStore } from '../store'
import { callPhoneV1, callPhoneV2, getVersion } from '../api'

import { useStore } from '@/states/index'
const store = useStore()

const { t } = useI18n()

const loading = ref(false)
const countryShortName = ref('');
const authStore = useAuthStore()

const version = ref('')

const sendPhone = async () => {
  if (authStore.timeout) {return}

  const isValid = isValidPhoneNumber(authStore.phoneNumber, countryShortName.value)
  if (!isValid) {
    errorToast({ description: t('auth.toast.incorrectPhone') })
    return
  }

  const requestFn = authStore.authType === 0 ? callPhoneV1 : callPhoneV2

  loading.value = true
  const { success, timeout: delay, auth } = await requestFn({
    info: await getDevice(),
    phone: authStore.phoneNumber,
    token: store.pushToken,
    version: import.meta.env.VITE_VERSION,
  })

  loading.value = false

  if (!success) {
    warningToast({ description: t('toast.somethingWrong') })
    return
  }

  authStore.timeout = delay
  authStore.token = auth
  authStore.step = 1
}

const init = async () => {
  const { type, current } = await getVersion()
  version.value = `${import.meta.env.VITE_VERSION || current} \n ${type}`
}

onMounted(() => {
  init()
  store.$patch({ isDemoMode: false });
  countryShortName.value = 'RU';
})

watch(countryShortName, () => {
  if (countryShortName.value === 'RU') {
    authStore.authType = 1;
  } else {
    authStore.authType = 0;
  }
}, { immediate: true })

const router = useRouter()
const setDemoMode = () => {
  store.$patch({ isDemoMode: true })
  router.replace('/')
}
</script>

<template>
  <HeaderFullpage :title="t('accounts.title')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase v-else style="margin-bottom: 80px;">
    <div v-if="!accounts.length && !isLoading" class="info">
      {{ t('accounts.null') }}
    </div>
    <div
      v-if="isLoading"
      class="info"
    >
      {{ t('common.loading') }}
    </div>

    <AddEntity to="/account-create" />

    <div class="pet-list">
      <EntityItem
        v-for="item in accounts"
        :key="item.id"
        :item="item"
        :to="`/account/${item.id}?isMy`"
        @show-context="openContext({ id: item.id, hasShowcase: item.hasShowcase })"
      />
    </div>
  </LayoutBase>

  <ContextPane
    ref="ContextPaneREF"
    :actions="actions"
    @on-delegate="onDelegate"
    @on-fork="onFork"
    @on-edit="onEdit"
    @on-delete="onDelete"
    @add-showcase="onAddShowcase"
    @edit-showcase="onAddShowcase"
  />
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { HeaderFullpage } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { ContextPane } from '@/components/ContextPane'
import AddEntity from '@/components/AddEntity.vue';
import EntityItem from '@/components/EntityItem.vue';
import { useRouter } from 'vue-router'
import { getAccounts, deleteAccount } from './api'
import { useConfirm } from '@/composables/useConfirm';
import { successToast, errorToast } from '@/helpers/showToast'

import { PageLoader } from '@/components/PageLoader'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const router = useRouter()

const isLoading = ref(true)
const accounts = ref([])
const ContextPaneREF = ref()

const init = async () => {

  const { accounts: accs } = (await getAccounts())
  accounts.value = accs
  isLoading.value = false
}

const onEdit = (id) => {
  router.push(`/account-edit/${ id}`)
}

const onFork = (id) => {
  router.push(`/account-create?fork=${ id}`)
}

const { onConfirm } = useConfirm();
const onDelete = async (id) => {
  const response = await onConfirm({ title: t('accounts.toast.confirmToDelete') })
  if (response) {
    const { success } = await deleteAccount({ id })

    if (!success) {
      errorToast({ description: t('accounts.toast.deleteFail') })
      return
    }
    if (success) {
      successToast({ description: t('accounts.toast.deleteSuccess') })
      accounts.value = accounts.value.filter(el => el.id !== id)

    }
  }
}

const onDelegate =(id)=>{
  router.push(`/delegate?id=${id}`)
}
const onAddShowcase = (id) => {
  router.push(`/account-showcases/${id}`)
}

const doesAccountHasShowcase = ref(false);
const openContext = ({ id, hasShowcase }) => {
  doesAccountHasShowcase.value = hasShowcase;
  ContextPaneREF.value.open({ id })
}

const actions = computed(() => {
  if (doesAccountHasShowcase.value) {
    return ['edit', 'edit-showcase', 'delegate', 'fork', 'delete']
  }

  return ['edit', 'add-showcase', 'delegate', 'fork', 'delete']
})

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.pet-list {
    display: grid;
    gap: 10px;
}

.info {
    color: #C6C8DA;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.add-pet {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 11;
    border-radius: 50%;
    box-shadow: 0px 5px 20px 0px rgba(70, 85, 196, 0.70);
}
</style>

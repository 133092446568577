
import parsePhoneNumber from 'libphonenumber-js'

export const getMaskedPhone = (rawPhone) => {
  if(rawPhone) {
    const phoneNumber = parsePhoneNumber(`+${rawPhone}`) || parsePhoneNumber(rawPhone)
    const processedFormNumber = phoneNumber.formatInternational();

    return processedFormNumber;
  }

  return rawPhone
}

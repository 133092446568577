
import { CupertinoPane } from 'cupertino-pane';
import { onMounted, watch, ref, computed, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { SafeAreaView } from '@/helpers/getSafeArea'
import { useDevice } from '@/composables/useDevice';

const BOUNCED = false

const DEFAULT_BREAKPOINTS = {
  top: { enabled: true, height: window.innerHeight - SafeAreaView.top, bounce: BOUNCED },
  middle: { enabled: true, height: 200, bounce: BOUNCED },
  bottom: { enabled: true, height: 100, bounce: BOUNCED },
}

const settings = {
  simulateTouch: true,

  breaks: DEFAULT_BREAKPOINTS,
  buttonDestroy: false,
  initialBreak: 'top',
  showDraggable: false,
  fitHeight: false,
  parentElement: document.body,
  // handleKeyboard: true, // TODO: bug
  // upperThanTop: true,
  // lowerThanBottom: false

  // topperOverflow: false,
  // ionContentScroll: true,
  // touchMoveStopPropagation: true,
}

const isInit = ref(false)

const myPane = ref(null)
const position = ref('')

let onceTime
const pageMaxHeight = (window.innerHeight - SafeAreaView.top) - 100
export const usePane = () => {
  const route = useRoute()
  const pane = computed(() => route.meta?.pane || 'ordinary')

  const presets = {
    async ordinary() {

      await this._animatePanel()
      await myPane.value.enableDrag()
      await new Promise((res) => setTimeout(res, 0))
      await myPane.value.setBreakpoints({
        ...DEFAULT_BREAKPOINTS,
        top: { enabled: true, height: pageMaxHeight, bounce: BOUNCED },
        middle: { enabled: true, height: pageMaxHeight / 1.5, bounce: BOUNCED },
        bottom: { enabled: true, height: 100, bounce: BOUNCED }
      })

      // const { params = '' } = getUrlParams()
      // await myPane.value.moveToBreak(params.pane || 'middle')
      await myPane.value.moveToBreak('top')

      await myPane.value.enableDrag()

    },
    async fullpage() {
      await this._animatePanel()
      await new Promise((res) => setTimeout(res, 0))
      await myPane.value.setBreakpoints({
        top: { enabled: true, height: (window.innerHeight), bounce: BOUNCED },
        middle: { enabled: false },
        bottom: { enabled: false }
      })
      await myPane.value.moveToBreak('top')
      myPane.value.disableDrag()
    },
    async animal() {
      await this._animatePanel()
      await new Promise((res) => setTimeout(res, 0))
      await myPane.value.setBreakpoints({
        ...DEFAULT_BREAKPOINTS,
        top: { enabled: true, height: (window.innerHeight), bounce: BOUNCED },
        middle: { enabled: true, height: window.innerHeight / 2, bounce: BOUNCED },
        bottom: { enabled: false, height: 100, bounce: BOUNCED }
      })

      await myPane.value.moveToBreak('middle')
      myPane.value.enableDrag()
    },

    async menuPage() {
      await this._animatePanel()
      await new Promise((res) => setTimeout(res, 0))

      await myPane.value.setBreakpoints({
        ...DEFAULT_BREAKPOINTS,
        top: { enabled: true, height: this._getFitContent(), bounce: BOUNCED },
        middle: { enabled: true, height: this._getFitContent() / 2, bounce: BOUNCED },
        bottom: { enabled: true, height: 90, bounce: BOUNCED }
      })

      //открывать первый раз в среднем положении
      if (onceTime) {
        await myPane.value.moveToBreak('top')
      } else {
        await myPane.value.moveToBreak('middle')
        onceTime = true
      }

      myPane.value.enableDrag()
    },

    async fitContent() {
      await this._animatePanel()
      await myPane.value.setBreakpoints({
        ...DEFAULT_BREAKPOINTS,
        top: { enabled: true, height: this._getFitContent(), bounce: BOUNCED },
        middle: { enabled: false, },
        bottom: { enabled: false, }
      })
      await myPane.value.moveToBreak('top')
      myPane.value.disableDrag()
    },

    _animatePanel: async () => {
      // await myPane.value.hide()
      await myPane.value.present()
    },
    _getFitContent: () => myPane.value.el.querySelector('#content').offsetHeight
  }

  const { isMobileDevice } = useDevice();
  const onPaneInit = () => {
    if (!isMobileDevice.value) {return}

    if (isInit.value) {return}
    myPane.value = new CupertinoPane('.cupertino', { ...settings });

    myPane.value.on('onTransitionEnd', debounce(async () => {
      await new Promise((res) => setTimeout(res, 0))
      // console.log(myPane.value.currentBreak())
      const currentBreak = myPane.value.currentBreak() || 'top'
      if (position.value !== currentBreak) {
        position.value = currentBreak
      }
    }, 0));

    presets[pane.value]()

    isInit.value = true

  }

  // const test = async () => {
  //     await new Promise((res) => setTimeout(res, 400))
  //     if (route.meta?.pane) {
  //         presets[route.meta?.pane]()
  //     }
  // }

  onMounted(() => {
    nextTick(() => {
      onPaneInit()
    })
  })

  watch(() => route.path, () => {
    if (!isMobileDevice.value) {return}
    presets[pane.value]()
  })

  return { position, myPane, presets, isInit, pane }
}

function debounce(f, ms = 0) {
  let isCooldown = false;

  return function () {
    if (isCooldown) {return;}

    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => isCooldown = false, ms);
  };

}

<template>
  <div
    class="showcase__group__container"
    style="display: flex; flex-direction: column"
  >
    <div class="showcase__group__input">
      <menu-input
        v-model="search"
        :placeholder="t('menu.placeholder')"
      />
    </div>

    <div
      v-if="!filteredItems.length"
      class="showcase__group__notfound"
    >
      <p class="showcase__group__notfound__text">
        {{ t('common.notResultLong') }}
      </p>
    </div>

    <template v-else>
      <showcase-product-card
        v-for="item in filteredItems"
        :key="item.id"
        :item="item"
        :to="{
          name: 'account-showcases-products-single',
          params: {
            id: accountId,
            productId: item.id,
          },
        }"
      />

      <div class="flex-center" style="padding: 40px; width: 100%;">
        <SpinerBase v-if="isFetching" />
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'

import ShowcaseProductCard from '@/components/ShowcaseProductCard/ShowcaseProductCard.vue'
import { MenuInput, SpinerBase } from '@/UI'
import { ShowcasesItemTypes } from '@/screens/Account/constants/showcases'

import showcasesApi from '@/screens/Account/api/showcases.api'

const props = defineProps({
  items: {
    type: Array,
    default: () => {},
  },
  accountId: {
    type: [Number, String],
    default: '',
  },
  type: {
    type: Number,
    default: null,
    validate(value) {
      return Object.values(ShowcasesItemTypes).includes(value)
    },
  }
})

const search = ref('');
const lazyLoadItems = ref([]);
const mergedItems = computed(() => {
  if (!lazyLoadItems.value?.length) {
    return props.items;
  }

  return lazyLoadItems.value
})

const filteredItems = computed(() => {
  const result = mergedItems.value.filter(item => {
    const isNameMatches = item.name?.toLowerCase().includes(search.value.toLocaleLowerCase());
    const isDescriptionMatches = item.description?.toLowerCase().includes(search.value.toLocaleLowerCase());
    return isNameMatches || isDescriptionMatches
  })
  return result;
})

const limit = ref(10);
const totalItems = ref(null)

const fetchData = async () => {
  if (!props.accountId) {
    return null;
  }
  const { data, total } = await showcasesApi.getItems(props.accountId, {
    type: props.type,
    limit: limit.value,
    offset: lazyLoadItems.value?.length
  })
  totalItems.value = total;
  lazyLoadItems.value.push(...data);
}

onBeforeMount(async () => {
  fetchData()
})

const WRAPPER_BLOCK = document.querySelector('.cupertino');

const isFetching = ref(false);
useInfiniteScroll(
  WRAPPER_BLOCK,
  async () => {
    if (!isFetching.value) {
      isFetching.value = true;
      await fetchData();
      isFetching.value = false;
    }
  },
  { distance: 600,
    canLoadMore: () => {
      if (mergedItems.value?.length === 0) {
        return false;
      }

      if (mergedItems.value?.length >= totalItems.value) {
        return false;
      }

      return true;
    }
  }
)
</script>

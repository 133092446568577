<template>
  <HeaderFullpage />
  <PageLoader v-if="!loaded" />
  <LayoutBase
    v-else
    gap="40"
    padding="0"
  >
    <div class="account-products-single">
      <swiper
        v-if="computedData?.files"
        :modules="[FreeMode, Pagination]"
        :slides-per-view="1"
        :loop="false"
        :autoplay="false"
        :pagination="true"
      >
        <swiper-slide v-for="item in computedData.files">
          <img
            v-if="typeof item === 'string'"
            :src="item"
            :alt="computedData?.name"
            class="account-products-single__img"
          >
        </swiper-slide>
      </swiper>

      <div class="account-products-single__info">
        <h3 class="account-products-single__title">
          {{ title }}
        </h3>

        <div v-if="isFile" class="account-products-single__title">
          <a :href="computedData.files[0].path">
            {{ computedData.files[0].name }}
          </a>
        </div>

        <div v-if="price" class="account-products-single__pricing">
          <div class="account-products-single__price">
            {{ price }} {{ currency?.short }}
          </div>
          <div
            v-if="Number(computedData?.discount)"
            class="account-products-single__oldprice"
          >
            {{ price }} {{ currency?.short }}
          </div>
        </div>

        <div class="account-products-single__description">
          <h4 class="account-products-single__title">
            {{ t('common.description') }}
          </h4>

          <div
            v-if="computedData?.description"
            class="account-products-single__description__text"
          >
            <p class="account-products-single__description__text__item">
              {{ computedData?.description }}
            </p>
          </div>
        </div>

        <!-- <template v-if="computedData?.options?.characteristics">
          <h4 class="account-products-single__block-title">
            Характеристики
          </h4>

          <div class="account-products-single__characteristics">
            <AppDataTable
              :headers="headers"
              :items="computedData?.options?.characteristics"
              hide-header
            />
          </div>
        </template> -->
      </div>
    </div>
  </LayoutBase>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { HeaderFullpage } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { PageLoader } from '@/components/PageLoader'
import { useRoute } from 'vue-router'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { FreeMode, Pagination } from 'swiper/modules'
import { useMainEntities } from '@/states/mainEntities'
import { useI18n } from 'vue-i18n'

import showcasesApi from '@/screens/Account/api/showcases.api'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

const route = useRoute()
const loaded = ref(false)

const computedData = ref({});

onMounted(async () => {
  const { data } = await showcasesApi.getItemById(route.params.productId)
  computedData.value = data;
  loaded.value = true
})

const mainEntities = useMainEntities()
const currency = computed(() => mainEntities.getCurrencyById(computedData.value?.currency_id))

const price = computed(() => {
  if (Number(computedData.value?.discount)) {
    return Math.floor(computedData.value?.price - computedData.value?.price * computedData.value?.discount / 100);
  }
  return computedData.value?.price;
})

const { t } = useI18n();

const volume_id = computed(() => mainEntities.getVolumeById(computedData.value?.volume_id))
const title = computed(() => {
  const volume = t(`volume.${volume_id.value?.name}`)
  const secondPart = computedData.value?.amount ? `${computedData.value?.amount}${volume}` : '';

  return `${computedData.value?.name} ${secondPart}`
})

const isFile = computed(() => computedData.value?.files && computedData.value?.files[0] && computedData.value?.files[0] instanceof Object)
</script>

<style lang="scss">
[theme="dark"] {
  .account-products-single__description__text__item {
    color: #ffffff;
  }
}
.account-products-single {
  width: 100%;
  overflow: hidden;
  .swiper {
    width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
  }

  .swiper-pagination {
    bottom: 0;

    &-bullet {
      background-color: #d9d9d9;

      &-active {
        background-color: #5b43ef;
      }
    }
  }

  &__img {
    width: 100%;
    height: 414px;
    object-fit: cover;
    background-color: var(--emptySpaceBackground);
  }

  &__info {
    padding: 0 20px 20px 20px;
  }

  &__title {
    @include font-bold;
    font-size: 20px;
    line-height: 26px;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    color: var(--defaultFontColor);
  }

  &__pricing {
    width: 100%;
    padding: 10px;
    background-color: var(--defaultBackground);
    border-radius: 26px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__price {
    border-radius: 16px;
    background-color: #7bd720;
    padding: 20px 15px;
    color: #fff;

    font-size: 20px;
    @include font-bold;
    line-height: 14px;
    margin-right: 10px;
  }

  &__oldprice {
    font-size: 20px;
    @include font-medium;
    line-height: 14px;
    color: #8e92af;
    text-decoration: line-through;
  }

  &__description {
    &__title {
      font-size: 20px;
      @include font-bold;
      line-height: 26px;
      margin-bottom: 20px;
    }

    &__text {
      background: var(--defaultBackground);
      border: 1px solid #e8eafc;
      border-radius: 20px;
      padding: 20px;
      min-height: 220px;

      &__item {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.1px;
        padding: 0;
        margin: 0;
        text-wrap: pretty;

        color: #5b5f7c;
      }
    }
  }

  &__block-title {
    font-size: 16px;
    color: var(--defaultFontColor);
  }

  &__characteristics {
    background-color: var(--defaultBackground);
  }
}
</style>

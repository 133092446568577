<template>
  <HeaderFullpage
    desctop
    :title="t('orderView.title')"
  />
  <PageLoader v-if="isLoading" />
  <LayoutBase v-else style="padding-top: 80px;">
    <div
      class="head "
      :class="{ top: position === 'top', 'desctop': !isMobileDevice }"
    >
      <div class="stars hide">
        <img src="@/assets/icons/other/star.svg">4.9
      </div>
      <IconBase
        :path="`category/${order.category_id}.svg`"
        class="logo"
      />
      <div
        v-if="myCoords && order.payload.coords"
        class="distance"
      >
        <img src="@/assets/icons/other/distance.svg">{{
          getDistance(
            order.payload.coords,
            myCoords
          ) }} {{ t('common.km') }}
      </div>
    </div>
    <div class="title">
      {{ order.payload?.name }}
    </div>
    <div class="subtitle">
      {{ nameOfCatefory }}
    </div>
    <div class="action__wrapper">
      <!-- <button class="action">
                <img src="@/assets/icons/other/phone.svg" class="action__img">
            </button> -->
      <button
        class="action chat-action"
        @click="handleOpenChat"
      >
        <img
          src="@/assets/icons/other/chat.svg"
          class="action__img"
        >
        {{ t('chat.replyShort') }}
      </button>
      <button
        class="action"
        :class="{ favorite: order.is_favourite }"
        @click="addFavorite(order.is_favourite)"
      >
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          class="action__img"
        >
          <path
            d="M19.3 5.71002C18.841 5.24601 18.2943 4.87797 17.6917 4.62731C17.0891 4.37666 16.4426 4.2484 15.79 4.25002C15.1373 4.2484 14.4909 4.37666 13.8883 4.62731C13.2857 4.87797 12.739 5.24601 12.28 5.71002L12 6.00002L11.72 5.72001C10.7917 4.79182 9.53273 4.27037 8.22 4.27037C6.90726 4.27037 5.64829 4.79182 4.72 5.72001C3.80386 6.65466 3.29071 7.91125 3.29071 9.22002C3.29071 10.5288 3.80386 11.7854 4.72 12.72L11.49 19.51C11.6306 19.6505 11.8212 19.7294 12.02 19.7294C12.2187 19.7294 12.4094 19.6505 12.55 19.51L19.32 12.72C20.2365 11.7823 20.7479 10.5221 20.7442 9.21092C20.7405 7.89973 20.2218 6.64248 19.3 5.71002Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <button
        class="action"
        @click="handleCopy"
      >
        <img
          src="@/assets/icons/other/share.svg"
          class="action__img"
        >
      </button>
    </div>
    <!--  <pre>
            {{ JSON.stringify(order, true, 2) }}
        </pre> -->
    <div class="address">
      {{ order.payload.address }}
    </div>
    <div class="gap">
      <template v-if="order?.category_id === 26">
        <ItemBase
          v-if="order.payload.reason"
          :title="t('orderView.reason')"
        >
          <InputSimple
            :model-value="order.payload.reason"
            disable
          />
        </ItemBase>
        <ItemBase
          v-if="order.payload.animalName"
          :title="t('orderView.petName')"
        >
          <InputSimple
            :model-value="order.payload.animalName"
            disable
          />
        </ItemBase>
      </template>
      <template v-else>
        <ItemBase
          v-if="order.payload.description"
          :title="t('orderView.desc')"
        >
          <TextareaBase
            fit-content
            :model-value="order.payload.description"
            disable
          />
        </ItemBase>

        <ItemBase v-if="order.payload.petName" :title="t('orderView.pet')">
          <div class="info-item info-item-pet">
            <div class="pet-icon-wrapper">
              <IconBase
                :path="`animals/${getAnimalById(order?.payload?.animal_id).icon}.svg`"
              />
            </div>

            <div class="pet-name">
              <div class="info-value">
                {{ order.payload.petName }}
              </div>
              <div class="info-label">
                {{ order.payload.breed }}
              </div>
            </div>
          </div>
        </ItemBase>

        <ItemBase :title="t('orderView.info')">
          <div class="info">
            <div
              v-if="order.payload.sex"
              class="info-item"
            >
              <div class="info-label">
                {{ t('common.sex') }}
              </div>
              <div class="info-value">
                {{ getSex(order.payload.sex) }}
              </div>
            </div>
            <div
              v-if="order.payload.age"
              class="info-item"
            >
              <div class="info-label">
                {{ t('common.age') }}
              </div>
              <div class="info-value">
                {{ fromDateToAge(order.payload.age) }}
              </div>
            </div>
            <div
              v-if="order.payload.weight"
              class="info-item"
            >
              <div class="info-label">
                {{ t('common.weight') }}
              </div>
              <div class="info-value">
                {{ order.payload.weight }} {{ t('common.kg') }}
              </div>
            </div>
          </div>

          <div class="block">
            <div class="block-item">
              <div class="block-label">
                {{ t('orderView.castration') }}
              </div>
              <div class="block-value">
                {{ order.payload.castration ? t('common.yes') : t('common.no') }}
              </div>
            </div>
            <div
              v-if="order.payload.lastCheckup"
              class="block-item"
            >
              <div class="block-label">
                {{ t('animal.checkup') }}
              </div>
              <div class="block-value">
                {{ order.payload.lastCheckup }}
              </div>
            </div>
            <div class="block-item">
              <div class="block-label">
                {{ t('orderView.chip') }}
              </div>
              <div class="block-value">
                {{ order.payload.chip ? t('common.yes') : t('common.no') }}
              </div>
            </div>
          </div>
        </ItemBase>

        <ItemBase
          v-if="order.payload.vaccinations?.length"
          :title="t('orderView.vac')"
        >
          <div class="block">
            <div
              v-for="vac in order.payload.vaccinations "
              class="block-item"
            >
              <div class="block-label">
                {{ vac.type }}
              </div>
              <div class="block-value">
                {{ vac.createdAt }}
              </div>
            </div>
          </div>
        </ItemBase>

        <ItemBase
          v-if="order.payload.photos?.length"
          :title="t('orderView.photo')"
        >
          <PhotoGallery
            v-model="order.payload.photos"
            only-view
          />
        </ItemBase>

        <ItemBase
          v-if="order.payload.chronicIlls"
          :title="t('orderView.chronic')"
        >
          <TextareaBase
            v-model="order.payload.chronicIlls"
            :rows="6"
            disable
          />
        </ItemBase>

        <ItemBase :title="t('orderView.doctor')">
          <CheckboxTabs
            v-model="order.payload.callDoctor"
            :options="callDoctorOptions"
            disabled
          />
        </ItemBase>
      </template>
    </div>
  </LayoutBase>
  <FollowElements>
    <BackButton />
  </FollowElements>

  <CreateChatPane
    :id="order.account_id"
    ref="CreateChatPaneREF"
    :offer-id="order?.id"
    :filter-by-category="order.category_id"
    is-order
  />
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { LayoutBase } from '@/components/Layouts';
import { FollowElements } from '@/components/PaneMain';
import { BackButton, CheckboxTabs, TextareaBase, ItemBase, InputSimple, HeaderFullpage, IconBase } from '@/UI';
import { getOrder, addToFavorite, removeFromFavorite } from './api'
import { useRoute } from 'vue-router'
import { useMainEntities } from '@/states/mainEntities'
import { fromDateToAge } from '@/helpers/fromDateToAge'
import { PhotoGallery } from '@/components/PhotoGallery'
import { getDistance } from '@/helpers';
import { PageLoader } from '@/components/PageLoader'
import { CreateChatPane } from '@/components/CreateChatPane'
import { successToast, errorToast } from '@/helpers/showToast'
import { share } from '@/helpers/share'
import { myCoords } from '@/helpers/getCurrentPosition'
import { getSex } from '@/helpers/getSex'
import { usePane } from '@/components/PaneMain';
import { useDevice } from '@/composables/useDevice';
import { useI18n } from 'vue-i18n'

import { useMapStore } from '@/states/mapStore'
import { getAnimalById } from '@/helpers/helpers';

const mapStore = useMapStore()

const { t } = useI18n()
const { isMobileDevice } = useDevice();

const mainEntities = useMainEntities()
const route = useRoute()
const isLoading = ref(true)
const order = reactive({})
const CreateChatPaneREF = ref()

const { position } = usePane()

const idPage = computed(() => route.params?.id)

const callDoctorOptions = computed(() => (
  [
    {
      value: true,
      label: t('common.yes')
    },
    {
      value: false,
      label: t('common.no')
    },
  ]
))

watch(idPage, () => init())

const init = async () => {
  isLoading.value = true
  const { id } = route.params
  if (!id) {return}
  const { orders } = await getOrder({ id })
  Object.assign(order, orders[0])

  mapStore.$patch({ openedPlace: [order.lat, order.lon] })

  order.payload.vaccinations = order.payload.vaccinations?.filter(el => el.createdAt || el.type)
  isLoading.value = false
}

const nameOfCatefory = computed(() => mainEntities.category.find(el => el.id === order?.category_id)?.name)

const handleOpenChat = () => {
  CreateChatPaneREF.value.open()
}

const addFavorite = async (isFavorite) => {
  if (!isFavorite) {
    const { success } = await addToFavorite({ id: order.id })
    if (success) {
      successToast({ description: t('orderView.toast.add') })
      order.is_favourite = true

    } else {
      errorToast({ description: t('orderView.toast.error') })
    }

  } else {
    const { success } = await removeFromFavorite({ id: order.id })
    if (success) {
      successToast({ description: t('orderView.toast.remove') })
      order.is_favourite = false

    } else {
      errorToast({ description: t('orderView.toast.error') })
    }
  }
}

const handleCopy = () => {
  share({
    title: order.payload.name,
    description: nameOfCatefory.value,
    url: `${import.meta.env.VITE_SHARE_URL}offer/${route.params.id}`
  }, t)
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
[theme="dark"] {
    .info-item {
        background: #37363C;
    }

    .info-value {
        color: #B0B2C2;
    }

    .info-label {
        color: #fff;
    }

    .block {
        background: #37363C;
    }

    .block-item {
        border-bottom: 1px solid #56555C;

        &:last-child {
            border-bottom: none;
        }
    }

    .block-label {
        color: #B0B2C2;
    }

    .block-value {
        color: #B0B2C2;
    }

    .title {
        color: #fff;
    }

    .subtitle {
        color: #B0B2C2;
    }

    .action {
        background: #37363C;
        border: 1px solid #37363C;
    }

    .distance {
        color: #B0B2C2;
    }

    .logo {
        background: #37363C;
        border: 1px solid #37363C;
    }

    .head {
        background: var(--cupertino-pane-background-dark);

        &.top {
            box-shadow: 0 2px 8px #1a1a1a
        }
    }
}

.hide {
    visibility: hidden;
}

.block {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.05);
    padding: 0 20px;

    &-item {
        &:not(last-child) {
            border-bottom: 1px solid #E6EAF3;
        }

        align-items: center;
        padding: 25px 0;
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        gap: 5px;
    }

    &-label {
        color: #5B5F7C;
        font-size: 15px;
        font-weight: 700;
        line-height: 14px;
    }

    &-value {
        color: #8E92AF;
        font-size: 15px;
        font-weight: 700;
        line-height: 14px;
    }
}

.info-item {
    padding: 18px 0;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.05);
    display: grid;
    grid-template-columns: minmax(0, auto);
    gap: 6px;
}

.info-label {
    color: #8E92AF;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
}

.info-value {
    color: #5B5F7C;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 14px;
}

.info {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.gap {
    display: grid;
    gap: 30px;
}

.itemWrapper {
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    padding: 20px;
}

.stars,
.distance {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.head {
    width: 100%;
    height: 60px;
    position: absolute;
    top: 0;
    margin-left: -20px;
    border-radius: var(--cupertino-pane-border-radius) var(--cupertino-pane-border-radius) 0 0;

    display: grid;
    justify-content: space-between;
    grid-auto-flow: column;

    padding: 10px;
    background: var(--cupertino-pane-background-light);
    z-index: 10;

    transition: box-shadow .3s;

    box-shadow: none;

    &.top {
        box-shadow: 0 2px 10px #e6e6e6;
    }

    &.desctop {
      position: relative;
      height: 120px;
      border-radius: 0;
      width: 100%;
      margin: auto auto 20px;
      background: transparent;

      &>.logo {
        transform: translate(-50%, calc(10px));
      }
    }
}

.logo {
    border-radius: 30px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-50%));
    transition: opacity .3s ease;

    &.top {
        opacity: 0;
        pointer-events: none;

    }
}

.title {
    color: #5B5F7C;
    text-align: center;
    font-size: 22px;
    font-weight: 700;

    margin-bottom: 10px;
    transition: all .3s;

    &.top {
        transform: scale(1.2) translateY(-20px);
    }
}

.subtitle {
    color: #8E92AF;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
    transition: all .3s;

    &.top {
        transform: scale(1.2) translateY(-20px);
    }
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid #E8EAFC;
    background: #FFF;
    width: 60px;
    height: 60px;

    color: #5B43EF;

    &.favorite {
        color: red;
    }
}

.action__wrapper {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.address {
    color: #8E92AF;
    font-size: 14px;
    font-weight: 500;

    text-align: center;
    margin: 20px;
}

.tabs {
    margin-bottom: 30px;
}

.services {
    display: grid;
    gap: 30px;

    &__item {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 20px;
    }

    &__icon {
        width: 20px;
        height: 20px;
    }

    &__title {
        color: #645C56;
        font-size: 15px;
        font-family: NunitoSemiBold;

    }
}

.schedule {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    font-size: 15px;
    font-weight: 600;

}

.info {
    display: grid;
    gap: 20px;

    &__item {
        color: #6B7090;
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
    }
}

.contacts {
    border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.05);
    margin: -20px;
    margin-top: 0;
    padding: 0 40px;

    &__item {
        display: grid;
        grid-template-columns: 20px 1fr;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 30px 0;

        color: #8E92AF;
        font-size: 14px;
        font-family: NunitoSemiBold;

        &:not(:last-child) {
            border-bottom: 1px solid #E6EAF3;
        }
    }
}

.chat-action {
  width: auto;
  padding: 0 25px;
  display: flex;
  gap: 15px;
}

.info-item-pet {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
}
.pet-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.pet-icon-wrapper {
  background: #F2F8FF;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  padding: 20px;
}
</style>

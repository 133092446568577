<template>
  <div class="enter-otp-confirmation__step">
    <div class="auth__subtitle">
      <template v-if="authStore.authType === 0">
        {{ t('auth.call') }} <br>
        {{ t('auth.enterLastFourChar') }}
      </template>
      <template v-else>
        {{ t('auth.sended') }}<br>{{ t('auth.atNumber') }}
        {{ authStore.phoneNumber }}
      </template>
    </div>

    <VOtpInput
      v-model="totpCode"
      length="4"
      :disabled="isRequestLoading"
      @update:model-value="sendOtpCode"
    />

    <app-progress-linear
      :class="[
        'enter-otp-confirmation__step__request-pending',
        {
          'enter-otp-confirmation__step__request-pending_active': isRequestLoading
        }
      ]"
    />

    <template v-if="authStore.authType === 0">
      <ButtonBase @click="sendOtpCode">
        {{ t('common.send') }}
      </ButtonBase>
    </template>

    <button-base
      variant="text"
      class="enter-otp-confirmation__step__resend"
      @click="resetStep()"
    >
      {{ t('auth.changeNumber') }}
    </button-base>
    <button-base
      :loading="loading"
      variant="text"
      class="enter-otp-confirmation__step__resend"
      :disabled="Boolean(authStore.timeout)"
      @click="sendSmsCode()"
    >
      {{ options[authStore.authType] }}
      <br>
      <span v-if="authStore.timeout">
        {{ t('auth.through') }} {{ authStore.timeout }} {{ t('auth.sec') }}
      </span>
    </button-base>

    <SupportView />
  </div>
</template>

<script setup>
// import VOtpInput from 'vue3-otp-input'
import { warningToast, successToast, errorToast } from '@/helpers/showToast'
import { auth, sendPhone, sendSms } from '../api'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useAuthStore } from '../store'
import ButtonBase from '@/UI/ButtonBase/ButtonBase.vue'
import AppProgressLinear from "@/components/AppProgressLinear/AppProgressLinear.vue";
import { useStore } from '@/states/index';

import SupportView from './SupportView.vue';

import { patchUser } from '@/screens/SettingsView/api';

const { t } = useI18n()

const options = [t('auth.callWithCode'), t('auth.send')]

const router = useRouter()
const otpRef = ref(null)
const authStore = useAuthStore()
const loading = ref(false)

const sendSmsCode = async () => {
  try {
    loading.value = true

    const request = authStore.authType === 0 ? sendPhone : sendSms;
    const { success } = await request({ auth: authStore.token })
    if (success) {
      successToast({
        description: t('auth.sended'),
      })
      otpRef.value.clearInput()
    } else {
      warningToast({ description: t('toast.somethingWrong') })
    }

    authStore.timeout = 60
  } catch (error) {
    console.error(error)
    warningToast({ description: t('toast.somethingWrong') })
  } finally {
    loading.value = false
  }
}

const store = useStore();

const isRequestLoading = ref(false);
const totpCode = ref('');
const sendOtpCode = async () => {
  if (totpCode.value.length === 4) {
    isRequestLoading.value = true;
    const {
      success,
      message,
      token: authToken,
    } = await auth({
      auth: authStore.token,
      pincode: totpCode.value
    })

    if (success) {
      patchUser({ language: store.language })
    }

    if (!success) {
      errorToast({ description: message })
      isRequestLoading.value = false;
      return
    }

    store.$patch({ authToken })
    router.replace('/')
    resetStep();
    authStore.phoneNumber = '';
  }
}

function resetStep() {
  authStore.step = 0
  authStore.token = ''
  authStore.timeout = 0
}
</script>

<style lang="scss" scoped>
:deep(.v-otp-input) {
  .v-otp-input__content {
    max-width: unset;
    gap: 20px;
    height: unset;
  }
  .v-field, .v-otp-input__field, .v-field__outline {
    height: 80px;
    width: 85px;
    border-radius: 20px;
  }
  .v-otp-input__field {
    font-size: 40px;
    text-align: center;
    background: white;
  }
  .v-field__outline {
    display: none;
  }
  .v-field__field {
    input {
      border: 1px solid transparent;
      &:focus {
        border-color: #4655c4
      }
    }
  }
}
</style>

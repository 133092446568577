
import { api } from "@/app/api"

export const getUser = async () => {
  try {
    const { data } = (await api.get('/v3/profile'))
    return { user: data.profile }
  } catch (error) {
    console.error(error)
  }
}

export const changeUser = async ({ user, language }) => {
  try {
    const { data } = await api.put('/v1/profile', { ...user, language })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const patchUser = async ({ language }) => {
  try {
    const { data } = await api.patch('/v1/profile', { language })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getContacts = async () => {
  try {
    const { data } = await api.get('/v1/contacts')
    return data
  } catch (error) {
    console.error(error)
  }
}
export const deleteContact = async ({ id }) => {
  try {
    const { data } = await api.delete('/v1/contacts', {
      data: {
        id
      }
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
export const changeContact = async (contact) => {
  try {
    const { data } = await api.put('/v1/contacts', contact)
    return data
  } catch (error) {
    console.error(error)
  }
}
export const sendContact = async (contact) => {
  try {
    const { data } = await api.post('/v1/contacts', contact)
    return data
  } catch (error) {
    console.error(error)
  }
}


<template>
  <HeaderFullpage
    class="mb"
    :title="props.customPage.headerTitle"
  />
  <PageLoader v-if="isLoading" />
  <LayoutBase
    v-else
    :gap="40"
  >
    <ItemBase :title="t('animal.kind')">
      <div class="gap">
        <SelectAnimalType v-model="pet.payload.animal_id" />
        <InputBase
          v-model="pet.payload.name"
          :label="t('animal.ph.petName')"
        />
        <InputBase
          v-model="pet.payload.breed"
          :label="t('animal.ph.breed')"
        />
      </div>
    </ItemBase>

    <CheckboxTabs
      v-model="pet.payload.sex"
      :options="sexOptionsComputed"
    />

    <div class="item-inner">
      <ItemBase :title="t('animal.bdate')">
        <InputSimple
          v-model="pet.payload.age"
          :name="t('animal.bdate')"
          data-maska="##.##.####"
          type="decimal"
          :placeholder="t('animal.ph.bdate')"
        />
      </ItemBase>
      <ItemBase :title="t('common.weight')">
        <InputSimple
          v-model="pet.payload.weight"
          :name="t('common.weight')"
          type="decimal"
          :placeholder="t('common.kg')"
          data-maska="###"
        />
      </ItemBase>
    </div>

    <ItemBase :title="t('animal.castration')">
      <CheckboxTabs
        v-model="pet.payload.castration"
        :options="castrationOptions"
      />
    </ItemBase>

    <ItemBase :title="t('animal.chip')">
      <CheckboxTabs
        v-model="pet.payload.chip"
        :options="chipOptions"
      />
      <InputSimple
        v-if="pet.payload.chip"
        v-model="pet.payload.chipNumber"
        name="Номер чипа"
        :placeholder="t('animal.ph.chip')"
      />
    </ItemBase>
    <ItemBase :title="t('animal.vac')">
      <div
        v-for="( inputVac, key ) in pet.payload.vaccinations "
        :key="key"
        class="vac"
      >
        <InputSimple
          v-model="inputVac.type"
          :placeholder="t('animal.ph.vacName')"
          class="vac__name"
        />
        <InputDate v-model="inputVac.createdAt" />
        <img
          v-if="pet.payload.vaccinations.length - 1 !== key"
          class="vac__delete"
          src="@/assets/icons/other/add.svg"
          alt=""
          @click="pet.payload.vaccinations = pet.payload.vaccinations.filter((el, idx) => idx !== key)"
        >
      </div>
    </ItemBase>
    <ItemBase :title="t('animal.checkup')">
      <div class="vac">
        <InputSimple
          v-model="pet.payload.lastCheckupDrug"
          :placeholder="t('animal.ph.vacName')"
          class="vac__name"
        />
        <InputDate v-model="pet.payload.lastCheckup" />
      </div>
    </ItemBase>
    <PhotoGallery v-model="pet.payload.photos" />
    <ItemBase :title="t('animal.chronic')">
      <TextareaBase
        v-model="pet.payload.chronicIlls"
        :rows="6"
        :placeholder="t('animal.ph.chronic')"
      />
    </ItemBase>

    <ButtonBase @click="submit">
      {{ props.customPage.buttonText }}
    </ButtonBase>
  </LayoutBase>
</template>

<script setup>
import { LayoutBase } from '@/components/Layouts'
import { HeaderFullpage, ItemBase, InputSimple, InputBase, CheckboxTabs, TextareaBase, ButtonBase, SelectAnimalType } from '@/UI'
import { ref, reactive, watch, onMounted, computed } from 'vue'
import { PhotoGallery } from '@/components/PhotoGallery'
import { getAnimalInfo, editAnimal, createAnimal } from './api'
import { successToast, errorToast } from '@/helpers/showToast'
import { useRouter, useRoute } from 'vue-router'
import { PageLoader } from '@/components/PageLoader'
import { chipOptions, castrationOptions, sexOptions } from './constants'
import { uploadPhoto } from '@/states/api'
import { isValide } from '@/helpers/validate'
import { InputDate } from '@/components/InputDate'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
  customPage: {
    type: Object
  },
  pageType: {
    type: String,
    required: true
  }
})

const sexOptionsComputed = computed(() => {
  //Только у "Насекомых" и "Рептилий и экзотики" есть бесполость
  const sexlessAnimals = [7, 11]

  if (sexlessAnimals.includes(pet.payload.animal_id)) {
    return sexOptions.value
  }
  return sexOptions.value.filter(el => el.value !== 'sexless')
})

const route = useRoute()
const router = useRouter()

const isLoading = ref(true)

let pet = reactive({
  payload:
    {
      animal_id: 1,
      sex: "male",
      castration: false,
      chip: false,
      chipNumber: "",
      weight: "",
      age: "",
      breed: "",
      name: "",
      photos: [],
      chronicIlls: "",
      vaccinations: [
        {
          createdAt: '',
          type: ''
        }
      ],
      lastCheckup: "",
      lastCheckupDrug: ""
    },
})
watch(pet, () => {
  if (pet.payload.vaccinations.length) {
    const { createdAt, type } = pet.payload.vaccinations.at(-1)
    if (createdAt && type) {
      pet.payload.vaccinations.push({
        createdAt: '',
        type: '',

      })
    }
  }
})

watch(() => pet.payload.chip, (val) => {
  if (val === false) { pet.payload.chipNumber = '' }
})

const validateParams = () => [
  {
    value: pet.payload.name,
    label: t('animal.toast.petName'),
  },
]

const submit = async () => {
  if (!isValide(validateParams(), t)) {
    return
  }

  isLoading.value = true

  const { paths } = await uploadPhoto({ photos: pet.payload.photos })
  pet.payload.photos = paths

  if (props.pageType === 'edit') {
    const { success } = await editAnimal(pet)

    if (success) {
      successToast({ description: t('animal.toast.change') })
      router.back()

    } else {errorToast({ description: t('animal.toast.notChange') })}
  }

  if (props.pageType === 'create') {

    const { success } = await createAnimal({ ...pet, payload: { ...pet.payload, photos: paths } })

    if (success) {
      successToast({ description: t('animal.toast.add') })
      router.back()
    } else {errorToast({ description: t('animal.toast.notAdd') })}
  }

  isLoading.value = false
}

const init = async () => {
  if (props.pageType === 'edit') {
    const { id } = route.params
    const { animal } = (await getAnimalInfo({ id }))
    const vaccinations = pet.payload.vaccinations
    pet = Object.assign(pet, (animal[0]));
    if (!pet.payload.vaccinations.length) {
      pet.payload.vaccinations = vaccinations
    }
  }
  isLoading.value = false
}

onMounted(() => {
  init()
})

</script>

<style lang="scss" scoped>
.mb {
    margin-bottom: 10px;
}

.gap {
    display: grid;
    gap: 10px;
}

.item-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.vac {
    display: grid;
    grid-template-columns: 1fr 130px 24px;
    align-items: center;
    gap: 10px;

    &__delete {
        transform: rotate(45deg);
    }
}
</style>

import { useMainEntities } from '@/states/mainEntities'

export function addDotsToTheEnd(maxLength, inputString) {
  if (inputString?.length <= maxLength) {return inputString}
  if (inputString) {
    return `${inputString.slice(0, maxLength) }...`
  }
  return inputString
}

export const getAnimalById = (id) => {
  const mainEntities = useMainEntities()
  return mainEntities.animalTypes.find(el => el.id === id)
}

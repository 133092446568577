<template>
  <div class="showcases__form">
    <div class="showcases__form__row">
      <InputBase
        v-model="payload.name"
        :label="t('showcase.label.productName')"
        class="showcases__form__row__field"
        :errors="v$.name.$errors"
      />
    </div>
    <div class="showcases__form__row">
      <TextareaBase
        v-model="payload.description"
        :label="t('showcase.label.description')"
        class="showcases__form__row__field"
      />
    </div>
    <div
      class="showcases__form__row"
      style="display: flex; gap: 14px;"
    >
      <div style="width: 60%;">
        <InputBaseNumber
          v-model="payload.price"
          :label="t('showcase.label.productPrice')"
          class="showcases__form__row__field"
          :errors="v$.price.$errors"
        />
      </div>
      <div style="width: 40%;">
        <SelectFullPage
          id="currencyPane"
          v-model="payload.currency_id"
          fit-content
          has-short-value
          :label="t('showcase.label.currency')"
          :items="currencies"
        />
      </div>
    </div>

    <div
      class="showcases__form__row"
      style="display: flex; gap: 14px;"
    >
      <div style="width: 40%;">
        <InputBaseNumber
          v-model="payload.amount"
          :label="t('showcase.label.amount')"
          class="showcases__form__row__field"
        />
      </div>
      <div style="width: 60%;">
        <SelectFullPage
          id="volumePane"
          v-model="payload.volume_id"
          has-short-value
          fit-content
          :label="t('showcase.label.volume')"
          :items="volumes"
        />
      </div>
    </div>

    <div class="showcases__form__row">
      <InputBaseNumber
        v-model="payload.discount"
        :label="t('showcase.label.discount')"
        class="showcases__form__row__field"
        :errors="v$.discount.$errors"
      />
    </div>

    <div class="showcases__form__row">
      <photo-gallery
        v-model="payload.files"
        button-type="text"
        activator-class-name="showcases__form__gallery"
      />
    </div>

    <div class="showcases__form__row">
      <h3
        v-if="payload.options?.length"
        class="showcases__form__row__title"
      >
        {{ t('showcase.label.options') }}
      </h3>

      <ProductCharacteristicsInput
        v-for="(item, index) in payload.options?.characteristics"
        v-model="item.key"
        v-model:value="item.value"
        @delete="deleteOptionsItem(index)"
      />

      <button-base
        class="add-characteristics__btn"
        variant="text"
        @click="addCharacteristic"
      >
        <template #prepend-icon>
          <plus-icon-outlined />
        </template>
        {{ t('showcase.label.addOption') }}
      </button-base>
    </div>

    <div class="showcases__form__actions">
      <slot
        name="actions"
        :item="payload"
      />
      <button-base
        class="showcases__form__btn"
        :loading="loading"
        @click="submit"
      >
        {{ submitBtnText || t('common.add') }}
      </button-base>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { InputBaseNumber, InputBase, TextareaBase, ButtonBase, SelectFullPage } from '@/UI'
import { PhotoGallery } from '@/components/PhotoGallery'
import { ShowcasesItemTypes } from '@/screens/Account/constants/showcases'

import { useVuelidate } from '@vuelidate/core'
import { useValidations } from '@/helpers/vuelidate-wrapper'
import ProductCharacteristicsInput from './ProductCharacteristicsInput.vue'
import PlusIconOutlined from '@/assets/icons/other/PlusIconOutlined.vue'

import { useMainEntities } from '@/states/mainEntities'

const mainEntities = useMainEntities()
const currencies = computed(() => mainEntities.currencies);
const volumes = computed(() => mainEntities.volumes);

const { required, maxValue } = useValidations()

const rules = {
  name: {
    required,
  },
  description: {
    required,
  },
  discount: {
  },
  price: {
    required,
    maxValue: maxValue(10000000),
  }
}

const defaultState = {
  name: '',
  description: '',
  price: '',
  amount: 1,
  currency_id: 1,
  volume_id: 1,
  discount: null,
  files: [],
  type: ShowcasesItemTypes.products,
  options: { characteristics: [] },
}
const loading = ref(false)

const props = defineProps({
  predefinedData: {
    type: Object,
    default: () => {},
  },
  handler: {
    type: Function,
    default: () => null,
  },
  submitBtnText: {
    type: String,
    default: '',
  },
})

const payload = ref({
  name: props.predefinedData?.name ?? '',
  description: props.predefinedData?.description ?? '',
  price: Number(props.predefinedData?.price),
  amount: Number(props.predefinedData?.volume) || 1,
  files: Array.isArray(props.predefinedData?.files)
    ? props.predefinedData?.files
    : [],
  type: ShowcasesItemTypes.products,
  currency_id: props.predefinedData?.currency_id || 1,
  volume_id: props.predefinedData?.volume_id || 1,
  discount: Number(props.predefinedData?.discount),
  options: props.predefinedData?.options ?? {
    characteristics: [],
  },
})

const v$ = useVuelidate(rules, payload)

async function submit() {
  const isValid = await v$.value.$validate()

  if (!isValid) {
    return
  }

  loading.value = true

  props.handler(payload.value).finally(() => {
    loading.value = false
  })
}

function reset() {
  payload.value = { ...defaultState };
  v$.value.$reset();
}

function addCharacteristic() {
  payload.value.options.characteristics.push({
    key: '',
    value: '',
  })
}

function deleteOptionsItem(index) {
  payload.value.options.characteristics.splice(index, 1)
}

defineExpose({
  reset,
})
</script>
